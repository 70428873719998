.icon-about-1 {
  width: 60px;
  height: 60px;
}

.icon-about-2 {
  width: 60px;
  height: 60px;
}

.icon-about-3 {
  width: 60px;
  height: 60px;
}

.icon-about-4 {
  width: 50px;
  height: 50px;
}

.icon-about-5 {
  width: 50px;
  height: 50px;
}

.icon-about-6 {
  width: 50px;
  height: 50px;
}

.icon-about-7 {
  width: 50px;
  height: 50px;
}

.icon-about-8 {
  width: 25.54px;
  height: 46px;
}

.icon-angle-down {
  width: 15.77px;
  height: 9px;
}

.icon-angle-left {
  width: 19px;
  height: 33px;
}

.icon-angle-right {
  width: 19px;
  height: 33px;
}

.icon-arrow-tl {
  width: 46px;
  height: 45.96px;
}

.icon-benefit-1 {
  width: 34px;
  height: 33px;
}

.icon-benefit-2 {
  width: 31px;
  height: 34px;
}

.icon-benefit-3 {
  width: 25px;
  height: 25px;
}

.icon-benefit-4 {
  width: 35px;
  height: 41px;
}

.icon-benefit-5 {
  width: 29px;
  height: 29px;
}

.icon-blog-grid {
  width: 23px;
  height: 23px;
}

.icon-blog-list {
  width: 23px;
  height: 23px;
}

.icon-bullet {
  width: 947.5px;
  height: 946.7px;
}

.icon-bullet-black {
  width: 947.5px;
  height: 946.7px;
}

.icon-check-solid {
  width: 1000px;
  height: 1000px;
}

.icon-checkmark-outline {
  width: 30px;
  height: 30px;
}

.icon-close {
  width: 17.01px;
  height: 17.01px;
}

.icon-cloud-sync {
  width: 59px;
  height: 56px;
}

.icon-cross {
  width: 21px;
  height: 21px;
}

.icon-dropdown {
  width: 16px;
  height: 10.31px;
}

.icon-enlarge {
  width: 25px;
  height: 25px;
}

.icon-envelope {
  width: 44px;
  height: 35px;
}

.icon-facebook {
  width: 509.86px;
  height: 982.8px;
}

.icon-faster {
  width: 108px;
  height: 66px;
}

.icon-gear {
  width: 57px;
  height: 59px;
}

.icon-grid {
  width: 21px;
  height: 21px;
}

.icon-health {
  width: 13px;
  height: 16px;
}

.icon-lang {
  width: 29.47px;
  height: 26.67px;
}

.icon-linkedin {
  width: 972.9px;
  height: 929.94px;
}

.icon-list {
  width: 20px;
  height: 13.58px;
}

.icon-maintenance {
  width: 18px;
  height: 14px;
}

.icon-menu {
  width: 24px;
  height: 16px;
}

.icon-menu-0 {
  width: 974.43px;
  height: 805.71px;
}

.icon-one-system {
  width: 124px;
  height: 116px;
}

.icon-phone {
  width: 16px;
  height: 16px;
}

.icon-phone-update {
  width: 39px;
  height: 60px;
}

.icon-reduce-costs {
  width: 132px;
  height: 125px;
}

.icon-rental {
  width: 17px;
  height: 17px;
}

.icon-search {
  width: 23px;
  height: 22px;
}

.icon-service {
  width: 17px;
  height: 17px;
}

.icon-whatsapp {
  width: 972.08px;
  height: 976.44px;
}

.icon-x {
  width: 30px;
  height: 27.72px;
}

.icon-youtube {
  width: 30px;
  height: 21px;
}